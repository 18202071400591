import * as React from "react";
import Logo from "../images/Hyloq/hyloq-logo-blue.svg";

const Footer = () => {
  return (
    <footer class="bg-gray-100 py-3 border-t border-gray-100">
      <div class="max-w-7xl mx-auto flex justify-between md:gap-0 md:p-4 p-2 gap-4">
        <div class="">
          <img class="h-16 w-auto" src={Logo} alt="" />
          <h1 class="text-center text-xl ml-4 font-medium text-gray-700 md:block hidden">
            Stand out in the noise.
          </h1>
        </div>

        <div class="flex items-center justify-center">
          <div class="relative">
            <h1 class="tracking-wide font-medium md:text-base text-sm text-gray-700">
              <a
                href="mailto:marketing@hyloq.com"
                class="hover:opacity-80 hover:underline"
              >
                <div class="inline-flex text-xl tracking-wide font-medium text-gray-700">
                  <svg
                    class="mr-2 h-6 w-6 pt-0.5 pb-0.5"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12 12.713l-11.985-9.713h23.971l-11.986 9.713zm-5.425-1.822l-6.575-5.329v12.501l6.575-7.172zm10.85 0l6.575 7.172v-12.501l-6.575 5.329zm-1.557 1.261l-3.868 3.135-3.868-3.135-8.11 8.848h23.956l-8.11-8.848z"
                      clip-rule="evenodd"
                    />
                  </svg>{" "}
                  <h1> marketing@hyloq.com</h1>
                </div>
              </a>
              <br />
              <a
                href="tel:813-597-9187"
                class="hover:opacity-80 hover:underline"
              >
                <div class="inline-flex text-xl tracking-wide font-medium text-gray-700 ">
                  <svg
                    class="mr-2 h-6 w-6 pt-0.5 pb-0.5"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M3.59 1.322l2.844-1.322 4.041 7.89-2.725 1.341c-.538 1.259 2.159 6.289 3.297 6.372.09-.058 2.671-1.328 2.671-1.328l4.11 7.932s-2.764 1.354-2.854 1.396c-7.861 3.591-19.101-18.258-11.384-22.281zm1.93 1.274l-1.023.504c-5.294 2.762 4.177 21.185 9.648 18.686l.971-.474-2.271-4.383-1.026.5c-3.163 1.547-8.262-8.219-5.055-9.938l1.007-.497-2.251-4.398zm7.832 7.649l2.917.87c.223-.747.16-1.579-.24-2.317-.399-.739-1.062-1.247-1.808-1.469l-.869 2.916zm1.804-6.059c1.551.462 2.926 1.516 3.756 3.051.831 1.536.96 3.263.498 4.813l-1.795-.535c.325-1.091.233-2.306-.352-3.387-.583-1.081-1.551-1.822-2.643-2.146l.536-1.796zm.95-3.186c2.365.705 4.463 2.312 5.729 4.656 1.269 2.343 1.466 4.978.761 7.344l-1.84-.548c.564-1.895.406-4.006-.608-5.882-1.016-1.877-2.696-3.165-4.591-3.729l.549-1.841z"
                      clip-rule="evenodd"
                    />
                  </svg>{" "}
                  <h1> +1 (813) 597-9187</h1>
                </div>
              </a>
            </h1>
          </div>
        </div>
      </div>

      <div class="w-full mx-auto border-t border-white"></div>

      <div class="copyright py-5 max-w-7xl mx-auto px-4 sm:px-6 flex md:items-center justify-between lg:px-8">
        <div class="flex justify-center space-x-2 order-2">
          <h1 class="text-xl tracking-wide font-medium text-gray-700 md:block hidden">
            <a class="hover:underline" href="/contact">
              Contact Us
            </a>
          </h1>

          <a
            href="https://www.facebook.com/Hyloq/"
            class="text-blue-500 hover:text-gray-700"
          >
            <span class="sr-only ">Facebook</span>
            <svg
              class="h-6 w-6"
              fill="currentColor"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                clip-rule="evenodd"
              />
            </svg>
          </a>

          <a
            href="https://www.linkedin.com/company/hyloq"
            class="text-blue-500 hover:text-gray-700"
          >
            <span class="sr-only">Linkedin</span>
            <svg
              class="h-6 w-6 pt-0.5 pb-0.5"
              fill="currentColor"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M0 0v24h24v-24h-24zm8 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.397-2.586 7-2.777 7 2.476v6.759z"
                clip-rule="evenodd"
              />
            </svg>
          </a>
        </div>

        <div class="mt-0 md:order-1">
          <p class="font-medium ">
            <a
              class="text-gray-700 hover:text-blue-500 hover:underline text-xs p-1"
              href="/terms-of-service"
            >
              Terms
            </a>{" "}
            <a
              class="text-gray-700 hover:text-blue-500 hover:underline text-xs p-1"
              href="/privacy-policy"
            >
              Privacy
            </a>{" "}
            <a
              class="text-gray-700 hover:text-blue-500 hover:underline text-xs p-1"
              href="/ccpa"
            >
              CCPA
            </a>{" "}
          </p>
          <p class="md:text-base text-sm tracking-wider font-medium text-gray-700">
            &copy; {new Date().getFullYear()} Hyloq, LLC.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
