import * as React from "react";
import Burger from "./Burger/Burger";
import Logo from "../images/Hyloq/Hyloq-white-logo (2).svg";

const Header = () => {
  return (
    <header class="bg-gradient-to-l from-secondaryBlue to-primaryBlue">
      <nav class="max-w-7xl mx-auto px-4 sm:px-6" aria-label="Top">
        <div class="w-full py-4 flex items-center justify-between border-b border-indigo-500 lg:border-none">
          <div class="flex items-center">
            <a href="/">
              <span class="sr-only">Hyloq</span>
              <img class="h-20 w-48" src={Logo} alt="" />
            </a>
          </div>

          <button
            type="button"
            class="ml-5 inline-flex items-center justify-center rounded-md text-white hover:text-gray-200"
            aria-controls="mobile-menu"
            aria-expanded="false"
          >
            <Burger />
          </button>
        </div>{" "}
      </nav>
    </header>
  );
};

export default Header;
